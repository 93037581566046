<template>
    <div class="modal-mask">
        <div class="modal-content-container">
            <div class="add-cot-modal-header">
                Adicionar
                <span @click="close" class="material-icons-outlined add-cot-modal-header-close">close</span>
            </div>
            <div class="add-cot-modal-body">
                <div class="edit-tab-inputs-container">
                    <div>
                        <p class="products-page__datetime-title">Cotação ID</p>
                        <input type="number" disabled v-model="selectedCot.cot_id" class="edit-tab-input">
                    </div>
                    <div>
                        <p class="products-page__datetime-title">Vendedores convidados</p>
                        <input type="number" v-model="selectedCot.vend_convidados" class="edit-tab-input">
                    </div>

                    <div>
                        <p class="products-page__datetime-title">Vendedores engajados</p>
                        <input type="number" v-model="selectedCot.vend_engajados" class="edit-tab-input">
                    </div>
                    <div>
                        <p class="products-page__datetime-title">Vendedores pedido</p>
                        <input type="number" v-model="selectedCot.vend_pedido" class="edit-tab-input">
                    </div>
                    <div>
                        <p class="products-page__datetime-title">Produtos cotação</p>
                        <input type="number" v-model="selectedCot.prod_cotacao" class="edit-tab-input">
                    </div>
                    <div>
                        <p class="products-page__datetime-title">Produtos comprados</p>
                        <input type="number" v-model="selectedCot.prod_comprado" class="edit-tab-input ">
                    </div>
                </div>
                <div class="edit-tab-buttons-container">
                    <StandardButton text="voltar" :action="close" />
                    <StandardButton text="Salvar" :action="save" />
                </div>
            </div>
        </div>
        <cc-loader-full v-if="isLoading"/>
    </div>
</template>

<script>
import StandardButton from '@/components/ui/buttons/StandardButton.vue';
import ClientService from '@/services/v3/client.service.js';

export default {
    name: 'AddCotModal',
    props: {
        close: {
            type: Function
        },
        cot:{
            type: Object
        }
    },
    components: {
        StandardButton,
    },
    data() {
        return {
            selectedCot: {
                cot_id: this.cot.id,
                cli_id: this.cot.client.id
            },
            svc: new ClientService(),
            isLoading: true
        }
    },
    mounted () {
        this.svc.getCot(this.selectedCot.cot_id).then(resp => {
            if (resp.data.data.length > 0){
                this.selectedCot = resp.data.data[0]
                this.selectedCot.cli_id = this.cot.client.id
            }
            this.isLoading = false
        });
    },
    methods: {
        save() {
            this.isLoading = true
            if (this.selectedCot.id) this.svc.updateCot(this.selectedCot).then(resp => {
                this.isLoading = false
                this.close()
            })
            else this.svc.saveCot(this.selectedCot).then(resp => {
                this.isLoading = false
                this.close()
            })
            
        }
    },
}
</script>

<style lang="scss" scoped>
.modal-mask {
    position: fixed;
    z-index: 4;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.3s ease;
    overflow-y: auto;
    font-size: 1vw;
}

.modal-content-container {
    background-color: #fff;
    border-radius: 12px;
    width: 70vw;
}

.add-cot-modal-header {
    background: #FF7110;
    border-radius: 10px 10px 0px 0px;
    font-weight: 500;
    font-size: 2.3em;
    color: #FFFFFF;
    padding: 1vh 2vw;
    display: flex;
    justify-content: space-between;
}

.add-cot-modal-header-close {
    font-size: 1.4em;
    cursor: pointer;
}

.add-cot-modal-body {
    padding: 5vh 1vw;
}

.edit-tab-inputs-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1vh 2vw;
}

.edit-tab-input {
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 8px !important;
}

.edit-tab-input:focus {
    border: 1px solid #ced4da !important;
    outline: none;
}

.products-page__datetime-title {
    font-size: 1.7em;
    font-weight: 400;
    letter-spacing: 0.15px;
    color: #898989;
}

.edit-tab-buttons-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 5vh;
    gap: 5vw;
}
</style>