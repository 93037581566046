import { render, staticRenderFns } from "./_requests.tab.vue?vue&type=template&id=2e166d8d&scoped=true&"
import script from "./_requests.tab.vue?vue&type=script&lang=js&"
export * from "./_requests.tab.vue?vue&type=script&lang=js&"
import style0 from "./_requests.tab.vue?vue&type=style&index=0&id=2e166d8d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e166d8d",
  null
  
)

export default component.exports