<template>
  <div class="page-container">
    <div class="page-route-select">
      <router-link to="/cliente/monitores/cotacoes" class="page-route-select-text">
        Home
      </router-link>
      <span class="material-icons page-route-select-icon">play_arrow</span>
      <div class="page-route-select-text">
        Painel de Operações
      </div>
    </div>
    <div class="page-title">
      Painel de Operações
    </div>
    <div class="clearfilters__container">
      <Button class="action-buttons" :iconName="'cancel'" title="Limpar Filtros" :text="'Limpar Filtros'" :action="cleanFilter"/>
    </div>
    <div class="requests-tab__actions-container">
      <StandardInput :placeholder="'Selecione o estado'" 
                     :title="'Estado'" 
                     :action="setStateValue" 
                     :value="filters.state"
                     class="requests-tab__actions-button-width"
                     :list="arraySelectState" 
                     :type="'select'"/>
      <StandardInput v-if="isResaleAdminLoggedIn"
                     :placeholder="'Selecione a revenda'" 
                     :title="'Revenda'" 
                     :action="setResaleValue"
                     :value="filters.resale" 
                     class="requests-tab__actions-button-width"
                     :list="arraySelectResale" 
                     :type="'select'"/>
      <StandardInput v-if="!isResaleAdminLoggedIn"
                     :placeholder="'Selecione a revenda'" 
                     :title="'Revenda'" 
                     :action="setResaleValue" 
                     :value="filters.resale" 
                     class="requests-tab__actions-button-width"
                     :list="[]" 
                     :type="'disabled'"/>
      <StandardInput :placeholder="'Selecione o status'" 
                     :title="'Status'" 
                     :action="setStatusValue" 
                     :value="filters.status"
                     class="requests-tab__actions-button-width"
                     :list="arraySelectStatus" 
                     :type="'select'"/>
        <StandardInput :placeholder="'Selecione sta. do Cliente'" 
                     :title="'Status do Cliente'" 
                     :action="setClientStatusValue" 
                     :value="filters.attendance_statuses"
                     class="requests-tab__actions-button-width" 
                     :list="attendance_statuses" 
                     :type="'select'"/>
    </div>
    <div class="requests-tab__actions-container">
      <StandardInput :placeholder="'Digite o número da cotação'" 
                     :title="'Nº Cotação'" 
                     :action="setIdValue" 
                     :value="filters.request_id"
                     class="requests-tab__actions-button-width"
                     :type="'text'"/>
      <StandardInput :placeholder="'Digite o nome'" 
                     :title="'Nome'" 
                     :action="setNameValue" 
                     :value="filters.client_name"
                     class="requests-tab__actions-button-width"
                     :type="'text'"/>
      <div class="requests-tab__datetime-container">
        <div class="requests-tab__input-title-wrapper">
          <span class="requests-tab__input-title">Data de Envio</span>
        </div>
        <div class="requests-tab__datetime-wrapper">
          <datetime class="datetime-input form-control" @input="set_created_at" :value="filters.created_at" ref="datepicker" auto type="date"></datetime>
          <span  class="material-icons date-icon">
            today
          </span>
        </div>
      </div>
      <div class="requests-tab__datetime-container">
        <div class="requests-tab__input-title-wrapper">
          <span class="requests-tab__input-title">Data Final</span>
        </div>
        <div class="requests-tab__datetime-wrapper">
          <datetime class="datetime-input form-control" :value="filters.expiration_at" @input="set_expiration_date" ref="datepicker" auto type="date"></datetime>
          <span  class="material-icons date-icon">
            today
          </span>
        </div>
      </div>
    </div>
    <div class="page-selected-filters-container">
	    <div v-if="filters.state" class="page-selected-filters">
        <div class="page-selected-filters-text">{{ filters.state.fu }}</div>
        <span @click="setStateValue('')" class="material-icons page-selected-filters-icon">close</span>
      </div>
      <div v-if="filters.resale && filters.resale.name" class="page-selected-filters">
        <div class="page-selected-filters-text">{{ filters.resale.name }}</div>
        <span @click="setResaleValue('')" class="material-icons page-selected-filters-icon">close</span>
      </div>
      <div v-if="filters.status" class="page-selected-filters">
        <div class="page-selected-filters-text">{{ filters.status.name }}</div>
        <span @click="setStatusValue('')" class="material-icons page-selected-filters-icon">close</span>
      </div>
      <div v-if="filters.request_id" class="page-selected-filters">
        <div class="page-selected-filters-text">{{ filters.request_id }}</div>
        <span @click="setIdValue('')" class="material-icons page-selected-filters-icon">close</span>
      </div>
      <div v-if="filters.client_name" class="page-selected-filters">
        <div class="page-selected-filters-text">{{ filters.client_name }}</div>
        <span @click="setNameValue('')" class="material-icons page-selected-filters-icon">close</span>
      </div>
      <div v-if="filters.created_at" class="page-selected-filters">
        <div class="page-selected-filters-text">{{ filters.created_at | formatDateOnly }}</div>
        <span @click="setCreatedAtDateValue('')" class="material-icons page-selected-filters-icon">close</span>
      </div>
      <div v-if="filters.expiration_at" class="page-selected-filters">
        <div class="page-selected-filters-text">{{ filters.expiration_at | formatDateOnly }}</div>
        <span @click="setExpirationAtDateValue('')" class="material-icons page-selected-filters-icon">close</span>
      </div>
    </div>
    <cc-loader v-show="loading && page == 1" class="default" />
    <no-data-available v-if="list.length == 0 && !loading" :text="'Nenhum resultado encontrado'" />
    <div v-show="!loading" class="request-page-table-container">
      <div v-show="list.length > 0" >
        <div class="page-table-header">
          <div class="page-table-header-text obs-width"></div>
          <div class="page-table-header-text date-exp-width">Data Exp / Nº Cotação</div>
          <div class="page-table-header-text client-width">Cliente / Status</div>
          <div class="page-table-header-text products-width">Produtos</div>
          <div class="page-table-header-text mid-column-width"></div>
          <div class="page-table-header-text competitors-width">Concorrentes</div>
          <div class="page-table-header-text state-width">Estado</div>
          <div class="page-table-header-text winners-width">Vencedores</div>
          <div style="display: flex; justify-content: center;" class="page-table-header-text actions-width">Ações</div>
        </div>
        <div v-for="(item, idx) in list" 
             :key="item.id + Math.random()"
             :class="getSpecialBackground(idx)">
          <!-- desktop -->
          <div class="page-table-row desktop">
            <div class="page-table-desc-column obs-width">
              <div class="page-table-header-mobile"></div>
              <div>
                <i v-if="item.ttp_nome || item.cot_observacao" class="fas fa-info-circle request-info" @mouseover="show_info(item, true)" @mouseout="show_info(item, false)"></i>
                <cc-popover :visible="!!item.show_info && (item.ttp_nome || item.cot_observacao)">
                  <div>
                    <p><strong>Observação:</strong> {{ item.cot_observacao }}</p>
                    <p class="mb-0"><strong>Condição de Pgto:</strong> {{ item.ttp_nome }}</p>
                  </div>
                </cc-popover>
              </div>
              
            </div>
            <div class="page-table-desc-column date-exp-width"><div class="page-table-header-mobile">Data Exp</div>
            {{ item.expiration_date | formatDate }} <br>
            {{ item.id }}
              <span @click="showAddCot=item" class="material-icons-outlined page-table-add-cot">add_circle_outline</span>
          </div>
            <div class="page-table-desc-column client-width">
              <div class="page-table-header-mobile">Cliente</div>
              <div>
                <AttendanceStatusCircle :status="item.attendance_status" />
                {{ item.client.group }}
              </div>
              <cc-status :status="item.status.name"></cc-status>
            </div>
            <div @click="can('REQUEST_PRODUCT', 'READ') && toggle_products(item.id)" class="page-table-desc-column products-width ">
              <div class="page-table-header-mobile">Produtos</div>
              <div class="products-list-btn">
                <span class="list-products" >
                  {{ item.products_count || '-' }}
                </span>
              </div>
              
            </div>
            <div class="page-table-desc-column mid-column-width"></div>
            <div @click="can('REQUEST_PRODUCT', 'READ') && toggle_providers(item.id)" class="page-table-desc-column competitors-width ">
              <div class="page-table-header-mobile">Concorrentes</div>
              <div class="competitors-list-btn">
                <span class="list-products">
                  {{ item.sellers_count || '-' }}
                </span>
              </div>
              
            </div>
            <div class="page-table-desc-column state-width"><div class="page-table-header-mobile">Estado</div>{{ item.state.fu }}</div>
            <div class="page-table-desc-column winners-width">
              <div class="page-table-header-mobile">Vencedores</div>
              <a class="show-winners"
                 :class="{ 'disabled' : !can('REQUEST', 'READ_WINNERS') || item.status.name == 'NÃO DIGITADA' }"
                 v-on:click="can('REQUEST', 'READ_WINNERS') && item.status.name !== 'NÃO DIGITADA' && goToWinners( item )">
                <span class="material-icons show-winners-icon">
                  shopping_cart
                </span>
              </a>
            </div>
            <div style="display: flex; justify-content: center;" class="page-table-desc-column actions-width">
              <div class="page-table-header-mobile">Ações</div>

              <div style="display: flex;  align-items: center;  justify-content: center;">
                <span class="material-icons schedule-icon"
                      title="Prorrogar data de expiração"
                      v-if="item.status.name != 'PEDIDO GERADO'"
                      :class="{ 'disabled' : (
                        item.status.name == 'PEDIDO GERADO' ||
                        item.status.name == 'CONGELADA' ||
                        !can('REQUEST', 'POSTPONE')) }"
                      aria-hidden="true"
                      @click="
                          !(item.status.name == 'PEDIDO GERADO' || item.status.name == 'CONGELADA') &&
                      can('REQUEST', 'POSTPONE') &&
                      changeDeadline(item)">
                  schedule
                </span>
                <i title="Fornecedores"
                  class="fas fa-users groups-icon"
                  @click="can('REQUEST', 'MANAGE_PROVIDERS') && open_sellers(item)"
                  :class="{ 'disabled' : (
                        !can('REQUEST', 'MANAGE_PROVIDERS')
                      ) }">
                </i>
                <i title="Ver Pedidos" 
                  @click="show_orders(item)"
                  aria-hidden="true"
                  class="fas fa-hand-holding-usd hand-icon" 
                  v-if="item.status.name == 'PEDIDO GERADO' && can('REQUEST_ORDER', 'READ')">
                </i>
                <span class="material-icons campaign-icon"
                      title="Reenviar notifição as vendedores"
                      @click="resend_notifications(item)"
                      v-if="can('REQUEST', 'NOTIFY') &&
                          (item.status.name == 'NÃO DIGITADA' ||
                          item.status.name == 'DIGITADA')">
                  campaign
                </span>
                <span class="material-icons refresh-icon"
                      title="Replicar Solicitações"
                      v-if="can('REQUEST', 'REPLICATE')"
                      @click="can('REQUEST', 'REPLICATE') && replicate_quotation(item)" >
                  autorenew
                </span>
                <span class="material-icons folder-icon"
                      v-if="can('REQUEST', 'MANAGE_OFFERS') && item.status.name != 'PEDIDO GERADO'"
                      title="Gerenciar produtos"
                      @click="request=item;show_manage_request_products_by_seller = true;">
                  folder
                </span>
                <span class="material-icons chart-icon"
                      v-if="item.status.name == 'PEDIDO GERADO' && can('REQUEST', 'READ_REPORT')"
                      aria-hidden="true"
                      title="Ver relatório"
                      @click="show_report(item)" >
                  trending_up
                </span>
                <span @click="setShowMoreModal(item)" class="material-icons-outlined answers-icon">settings</span>
              </div>
              

            </div>
          </div>
          <!-- mobile -->
          <div class="mobile" style="padding: 1vh 0;">
            <div class="mobile-table-row" style="margin-bottom: 1vh;">
              <div >
                <div class="page-table-header-mobile" style="text-transform: uppercase;">
                  <AttendanceStatusCircle :status="item.attendance_status" />
                  {{ item.client.group }}
                </div>
                {{ item.id }}
              </div>
              <a class="show-winners"
                 :class="{ 'disabled' : !can('REQUEST', 'READ_WINNERS') || item.status.name == 'NÃO DIGITADA' }"
                 v-on:click="can('REQUEST', 'READ_WINNERS') && item.status.name !== 'NÃO DIGITADA' && goToWinners( item )">
                <span class="material-icons show-winners-icon">
                  shopping_cart
                </span>
              </a>
            </div>
            <div class="mobile-table-row">
              <div>
                <div class="page-table-header-mobile">Data Exp</div>
                {{ item.expiration_date | formatDate }}
              </div>
              <div class="mobile-colum-center">
                <div class="page-table-header-mobile">Estado</div>
                {{ item.state.fu }}
              </div>
              <div class="mobile-colum-end">
                <div class="page-table-header-mobile">Status</div>
                <cc-status :status="item.status.name"></cc-status>
              </div>
            </div>
            <div class="mobile-table-row">
              <div @click="can('REQUEST_PRODUCT', 'READ') && toggle_products(item.id)">
                <div class="page-table-header-mobile">Produtos</div>
                <div class="products-list-btn">
                  <span class="list-products" >
                    {{ item.products_count || '-' }}
                  </span>
                </div>
              </div>
              <div class="mobile-colum-end" @click="can('REQUEST_PRODUCT', 'READ') && toggle_providers(item.id)">
                <div class="page-table-header-mobile">Concorrentes</div>
                <div class="competitors-list-btn">
                  <span class="list-products">
                    {{ item.sellers_count || '-' }}
                  </span>
                </div>
              </div>
            </div>
            <div class="mobile-table-row" style="margin-top: 2vh;">
              <span class="material-icons schedule-icon"
                      title="Prorrogar data de expiração"
                      v-if="item.status.name != 'PEDIDO GERADO'"
                      :class="{ 'disabled' : (
                        item.status.name == 'PEDIDO GERADO' ||
                        item.status.name == 'CONGELADA' ||
                        !can('REQUEST', 'POSTPONE')) }"
                      aria-hidden="true"
                      @click="
                          !(item.status.name == 'PEDIDO GERADO' || item.status.name == 'CONGELADA') &&
                      can('REQUEST', 'POSTPONE') &&
                      changeDeadline(item)">
                  schedule
                </span>
                <i title="Fornecedores"
                  class="fas fa-users groups-icon"
                  @click="can('REQUEST', 'MANAGE_PROVIDERS') && open_sellers(item)"
                  :class="{ 'disabled' : (
                        !can('REQUEST', 'MANAGE_PROVIDERS')
                      ) }">
                </i>
                <i title="Ver Pedidos" 
                  @click="show_orders(item)"
                  aria-hidden="true"
                  class="fas fa-hand-holding-usd hand-icon" 
                  v-if="item.status.name == 'PEDIDO GERADO' && can('REQUEST_ORDER', 'READ')">
                </i>
                <span class="material-icons campaign-icon"
                      title="Reenviar notifição as vendedores"
                      @click="resend_notifications(item)"
                      v-if="can('REQUEST', 'NOTIFY') &&
                          (item.status.name == 'NÃO DIGITADA' ||
                          item.status.name == 'DIGITADA')">
                  campaign
                </span>
                <span class="material-icons refresh-icon"
                      title="Replicar Solicitações"
                      v-if="can('REQUEST', 'REPLICATE')"
                      @click="can('REQUEST', 'REPLICATE') && replicate_quotation(item)" >
                  autorenew
                </span>
                <span class="material-icons folder-icon"
                      v-if="can('REQUEST', 'MANAGE_OFFERS') && item.status.name != 'PEDIDO GERADO'"
                      title="Gerenciar produtos"
                      @click="request=item;show_manage_request_products_by_seller = true;">
                  folder
                </span>
                <span class="material-icons chart-icon"
                      v-if="item.status.name == 'PEDIDO GERADO' && can('REQUEST', 'READ_REPORT')"
                      aria-hidden="true"
                      title="Ver relatório"
                      @click="show_report(item)" >
                  trending_up
                </span>
            </div>
          </div>
        </div>
      </div>
      <cc-loader v-show="loading && page > 1" class="default"/>
      <tr style="display: flex; justify-content: center; margin: 2em;" v-if="showPagination">
        <td colspan="10" style="height:6rem">
          <a v-on:click="next_page" class="load-more shadow">MOSTRAR MAIS</a>
        </td>
      </tr>
      <!-- <div class="box-footer clearfix not-print">
        <Paginacao :key="'pag'"
                   classes="orange"
                   :items_by_page_value="items_by_page_value"
                   :total="total_items"
                   :page="page"
                   v-on:change="load($event)">
        </Paginacao>
      </div> -->
    </div>
    <AddCotModal v-if="showAddCot" :cot="showAddCot" :close="()=>showAddCot=false"/>
    <MoreActionsModal v-if="showMoreModal" :client="showMoreModal" :closeDialog="()=>showMoreModal=false" :updatePage="()=>{}"/>
    <RequestProvidersModal :request_id="request_id" 
                           v-if="show_request_providers" 
                           :closeDialog="() => show_request_providers=false" />
    <ModalQuotationProductsProducts id="modal" 
                                    :quotation="request_id" 
                                    v-if="show_request_products" 
                                    :closeDialog="() => show_request_products=false" />
    <ChangeQuotationDeadlineModal id="modal" 
                                  :request="request" 
                                  v-if="show_deadline_modal" 
                                  @close="show_deadline_modal = false;" 
                                  @reload="load" />
    <ManageRequestSellersModal id="modal" 
                               :request="request" 
                               v-if="show_manage_request_sellers" 
                               @close="show_manage_request_sellers = false;load()" />
    <ManageRequestProductsBySellersModal id="modal" 
                                         :request="request" 
                                         v-if="show_manage_request_products_by_seller" 
                                         @close="show_manage_request_products_by_seller = false;load()" />
  </div>
</template>

<script>
import AttendanceStatusCircle from "@/components/shared/AttendanceStatusCircle";
import RequestProvidersModal from '@/modals/request-providers/request-providers.modal'
import ModalQuotationProductsProducts from "@/modals/requested-products/requested-products.modal";
import ChangeQuotationDeadlineModal from "@/modals/requests/deadline/request-deadline.modal";
import ManageRequestSellersModal from "@/modals/requests/manage-sellers/manage-request-sellers.modal";
import ManageRequestProductsBySellersModal from "@/modals/requests/products-by-seller/manage-request-products-by-seller.modal";
import RequestService from '@/services/v3/request.service'
import ErrorHandlerService from "@/services/ErrorHandlerService";
import MoreActionsModal from "@/components/lists/MoreActionsModal";
import Request from "@/models/request.model";
import { mapState } from 'vuex';
import { requestsMixin } from '@/mixins/requests.mixing'
import { perm_mixin } from '@/mixins/permission.mixin'
import { loaderMixin } from '@/mixins/sweet-loader.mixin'
import UserService from "@/services/v1/user.service"
import ClientService from "@/services/v3/client.service";
import ClientServiceV2 from "@/services/v2/client.service";
import RequestsServiceV2 from "@/services/v2/requests.service";
import StorageService from '@/services/TokenService';
import { typingRemoteRequestControlMixin } from "@/mixins/typing-remote-request-controls.mixin";
import Button from '@/components/ui/buttons/StandardButton.vue'
import Paginacao from "@/components/cliente/base-components/Pagination";
import StandardInput from '@/components/ui/inputs/StandardInput.vue';
import AddCotModal from "./AddCotModal.vue";

export default {
    components: {
        RequestProvidersModal,
        ModalQuotationProductsProducts,
        ChangeQuotationDeadlineModal,
        ManageRequestSellersModal,
        AttendanceStatusCircle,
        ManageRequestProductsBySellersModal,
        Button,
        Paginacao,
        StandardInput,
        AddCotModal,
        MoreActionsModal
    },
    mixins: [ requestsMixin, perm_mixin, loaderMixin, typingRemoteRequestControlMixin ],
    data() {
        return {
            isResaleAdminLoggedIn: undefined,
            arraySelectState: [],
            arraySelectResale: [],
            arraySelectStatus: [],
            loading: true,
            show_request_products: false,
            show_request_providers: false,
            show_deadline_modal: false,
            show_manage_request_sellers: false,
            show_manage_request_products_by_seller: false,
            showAddCot: false,
            request_id: null,
            showMoreModal: false,
            request: {},
            states: [],
            resales: [],
            clients: [],
            list: [],
            attendance_statuses: [
                { value: 'STARTED', text: 'Iniciado' },
                { value: 'WAITING_REQUEST', text: 'Aguardando cotação' },
                { value: 'ON_WATCH', text: 'Em acompanhamento' },
                { value: 'IMPLANTED', text: 'Implantado' },
                { value: 'SUSPENDED', text: 'Suspenso' },
                { value: 'ON_ALERT', text: 'Em alerta' },
            ],
            page: 1,
            page_size: 30,
            svc: new RequestService(),
            user_svc: new UserService(),
            client_svc: new ClientService(),
            v2_cli_svc: new ClientServiceV2(),
            v2_svc: new RequestsServiceV2(),
            storage_svc: new StorageService(),
            showPagination: false,
            revenda_id: null,
            status_options: [
                {
                    name: "Todos",
                    value: ""
                }
            ],
            filters: {
                created_at: null,
                expiration_at: null,
                status: null,
                request_id: null,
                client_id: null,
            }
        }
    }, 
    watch: {
        filters : {
            handler: function() {
                this.load(1)
            },
            deep: true
        }
    },
    methods: {
        getResaleId() {
          this.revenda_id = this.storage_svc.get("revenda");
          if (this.revenda_id < 2) {
            this.isResaleAdminLoggedIn = true
          } else {
            this.isResaleAdminLoggedIn = false
            this.filters.resale = {id: this.revenda_id}
          }
        },
        cleanFilter () {
          if (this.isResaleAdminLoggedIn) this.filters = {}
          else this.filters = {resale: {id: this.revenda_id}}
        },
        setStateValue(value) {
          this.filters.state = value
          this.load(this.page)
        },
        setResaleValue(value) {
          this.filters.resale = value
          this.load(this.page)
        },
        setStatusValue(value) {
          this.filters.status = value
          this.load(this.page)
        },
        setClientStatusValue(value) {
          this.filters.attendance_statuses = value
          this.load(this.page)
        },
        setIdValue(value) {
          this.filters.request_id = value
          this.load(this.page)
        },
        setNameValue(value) {
          this.filters.client_name = value
          this.load(this.page)
        },
        setCreatedAtDateValue(value) {
          this.filters.created_at = value
          this.load(this.page)
        },
        setExpirationAtDateValue(value) {
          this.filters.expiration_at = value
          this.load(this.page)
        },
        getSpecialBackground (idx) { if (idx % 2 != 0) return 'page-table-line-special' },
        set_expiration_date(val) {
            if(val) {
                this.$set(this.filters, 'expiration_at', moment(val, 'YYYY-MM-DD').format())
                this.$forceUpdate()
            }
        },
        set_created_at(val) {
            if(val) {
                this.$set(this.filters, 'created_at', moment(val, 'YYYY-MM-DD').format())
                this.$forceUpdate()
            }
        },
        load_resales() {
            return this.client_svc.load_resales().then(response => response.data).then(data => {
                this.resales = data.data
                this.resales.forEach(el => {
                this.arraySelectResale.push({
                  text: el.name,
                  value: el
                })
              })
            })
		},
        open_datepicker(idx) {
            document.getElementsByClassName('vdatetime-input')[idx].click()
        },
        calculate_perc(req, val) {
            return (val/req.qtd_pedidos)*100
        },
        show_info(item, show) {
            this.$set(item, 'show_info', show)
        },
        open_sellers(item) {
            this.request=item;
            this.show_manage_request_sellers = true;
        },
        setShowMoreModal(item){
          this.showMoreModal = {...item.client, attendance:{id: item.attendance_id}}
        },
        show_orders(item) {
            this.$router.push({ name: 'monitors-page', params: { tab: 'pedidos', request_id: item.id }})
        },
        show_report(item) {
            this.$router.push({ name: 'relatorios', params: { tab: 'analise', request_id: item.id }})
        },
        changeDeadline(request) {
            this.request = request
            this.show_deadline_modal = true;
        },
        next_page() {
            this.page++;
            this.load()
        },
        load_client_stores() {
			return this.v2_cli_svc.list_client_stores().then(response => response.data).then(data => {
				this.clients = data.data.map(cli => { return { id: cli.cli_id, name: cli.cli_nome_grupo } })
        if(this.$route.query.client_id) {
            this.filters.client_id = this.clients.find(cl => cl.id == this.$route.query.client_id)
        }
			})
		},
        goToWinners(item) {
            this.$router.push({ name: 'cliente-monitores-vencedores', params: { id: item.id }});
        },
        toggle_products(request_id, open = true) {
            this.request_id = request_id;
            this.show_request_products = open;
        },
        toggle_providers(request_id, open = true) {
            this.request_id = request_id;
            this.show_request_providers = open;
        },
        load_region_data() {
			    this.user_svc.region_data().then(response => response.data).then(data => {
				    this.cities = data.cidades.map(c => ({ id: c.cid_id, name: c.cid_nome }))
				    this.states = data.estados.map(c => ({ id: c.est_id, fu: c.est_sigla }))
            this.states.forEach(el => {
              this.arraySelectState.push({
                text: el.fu,
                value: el
              })
            })
			    })
	    	},
        resend_notifications(req) {
            this.confirm_action({
                message: 'Confirma envio de notificação?',
                subtitle: 'Os vendedores serão novamente notificados sobre essa cotação!',
                callback: () => {
                    this.v2_svc.notify_sellers(req.id, req.client.id).then(res => {
                        this.present_info('Notificações reenviadas com sucesso!')
                    })
                }
            })
        },
        reload() {
            this.page = 1
            this.load()
        },
        load() {
            this.loading = true;
            let params =  { ...this.filters, page: this.page, page_size: this.page_size }
            params.state = this.filters.state && this.filters.state.id ? this.filters.state.fu : null
            params.resale = this.filters.resale && this.filters.resale.id ? this.filters.resale.id : null
            params.status = this.filters.status && this.filters.status.value ? this.filters.status.value : null
            params.client_id = this.filters.client_id && this.filters.client_id.id ? this.filters.client_id.id : null
            params.attendance_statuses = this.filters.attendance_statuses ? [this.filters.attendance_statuses] : null
            this.svc.list(params).then(response => response.data).then(data => {

                if (this.page === 1) {
                    this.list = Request.convert(data);
                } else {
                    this.list = this.list.concat(Request.convert(data));
                }
                if (data.length >= this.page_size) {
                    this.showPagination = true;
                } else {
                    this.showPagination = false;
                }

                this.loading = false;
            })
            .catch(error => {
                this.loading = false;
                ErrorHandlerService.handle(error, this.$store, this.$store);
            });
        },
        update_breadcrumbs() {
            this.$store.dispatch('set_breadcrumbs', [
                {
                    name: "Painel de Operações",
                    route: 'monitors-page'
                },
                {
                    name: "Cotações",
                    route: 'monitors-page'
                }
            ]);
        },
        replicate_quotation(quotation){

            this.$swal.fire({
                title: "Confirma a replicação das solicitações?",
                text: "Suas solicitações serão geradas no monitor de ressuprimento!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Sim, realizar replicação!",
                cancelButtonText: "Não!"
            }).then(result => {
                if (result.value) {
                    this.loading = true;
                    this.service.replicate_quotantion(quotation.id)
                    .then(response => response.data)
                    .then(data => {
                        this.loading = false;
                        this.$swal.fire(
                            "Realizado!",
                            "Sua replicação foi gerada com sucesso!",
                            "success"
                        );
                    })
                    .catch(error => {
                        this.loading = false;
                        ErrorHandlerService.handle(error, this.$store, this.$store);
                    });
                }
            });
        }
    },
    computed: mapState(['statuses']),
    async created() {
        this.request_id = this.$route.params.id

        if (this.$route.query.name) {
          this.filters.client_name = this.$route.query.name
        }

        this.getResaleId()
        this.update_breadcrumbs()
        this.load_region_data()
        this.load_resales()
        this.load()
        setTimeout(() => {
          this.statuses.forEach((el) => {
          this.arraySelectStatus.push({
            text: el.name,
            value: el
          })
        })
        }, 2000);
    }
}
</script>
<style lang="scss" scoped>
  @import "../monitors.page";

.page-container {
  font-size: 1vw;
  margin-right: 4em;
}
.page-route-select {
  display: flex;
  align-items: center;
}
.page-route-select-text {
  font-weight: 400;
  font-size: 1.36em;
  line-height: 17px;
  color: #ABABAB;
  margin-right: 20px;
  cursor: pointer;
}
.page-route-select-icon {
  color: #ff7110;
  margin-right: 20px;
}
.page-title {
  font-weight: 600;
  font-size: 2.72em;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #605F5F;
  margin-top: 45px;
}
.clearfilters__container {
  display: flex;
  flex-direction: row-reverse;
  margin: 2em 0em;
}
.requests-tab__actions-container {
  display: flex;
  justify-content: space-between;
  margin: 2em 0em;
}
.requests-tab__actions-wrapper {
}
.requests-tab__input-title-wrapper {
  padding-bottom: 5px;
}
.requests-tab__input-title {
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #898989;
  font-size: 20px;
}
.answers-icon {
  font-size: 1.5em;
  color: #46AD5D;
  cursor: pointer;
  margin-right: 5px;
}
.requests-tab__product-input-container {
  display: flex;
  align-items: center;
}
.requests-tab__product-input {
  border: 1px solid #c2c2c3;
  font-size: 17px;
  padding: 11px;
  border-radius: 5px;
  width: 25vw;
}
.requests-tab__product-input:focus {
  border: 1px solid #c2c2c3 !important;
}
.requests-tab__user-info-input {
  border: 1px solid #c2c2c3;
  font-size: 17px;
  padding: 7px;
  border-radius: 5px;
  width: 30vw;
}
.requests-tab__user-info-input:focus {
  border: 1px solid #c2c2c3 !important;
}
.search-icon {
  position: relative;
  left: -35px;
  color:  #606060;
}
.select-input-width {
  width: 25vw !important;
}
.attendance-status-input-width {
  width: 30vw !important;
}
.page-table-add-cot{
  color: var(--primary-color);
  cursor: pointer;
}
.datetime-input {
  border-radius: 8px !important;
}
.date-icon {
  background-color: #FF7110;
  color: #fff;
  cursor: pointer;
  padding: 8px 10px;
  border-radius: 0px 8px 8px 0px;
  position: relative;
  left: -6px;
}
.requests-tab__datetime-container {
  margin: 0.3em 0em;
  width: 24%;
}
.requests-tab__actions-button-width{
  width: 24%;
}
.requests-tab__datetime-wrapper {
  display: flex;
}
.request-page-table-container {
  margin: 1.5em 0em;
}

.page-table-header {
  background: #FFFEFC;
  border: 0.5px solid #E5E5E5;
  font-weight: 400;
  color: #605F5F;
  display: flex;
  padding: 1em 0em;
}
.page-table-header-text {
  padding-left: 10px;
  font-weight: 600; 
  font-size: 1.19em;
  color: #505050;
}
.page-table-header-mobile{display: none;}
.page-table-row {
  display: flex;
  align-items: center;
  font-weight: 300;
  font-size: 1rem;
  color: #605F5F;
}
.page-table-desc-column {
  padding: 0.5em 0.7em;
}
.page-table-line-special {  
  background: #F7F7F7;
}
.obs-width {
  width: 2%;
}
.date-exp-width {
  width: 15%;
}
.num-quotation-width {
  width: 8%;
}
.products-width {
  width: 7%;
  text-align: center;
}
.mid-column-width {
  width: 1%;
}
.competitors-width {
  width: 7%;
  text-align: center;
}
.client-width {
  flex: 1;
}
.state-width {
  width: 8%;
  text-align: center;
}
.winners-width {
  width: 6%;
  text-align: center;
}
.actions-width {
  width: 18%;
}
.products-list-btn {
  background-color: #17A2B80D;
  color: #17A2B8;
  border-radius: 8px;
  cursor: pointer;
  text-align: center;
}
.competitors-list-btn {
  background-color: #2ACD721A;
  color: #30AA4C;
  border-radius: 8px;
  cursor: pointer;
  text-align: center;
}
.show-winners-icon {
  background-color: #FF7110;
  color: #fff;
  padding: 5px 10px;
  border-radius: 8px;
  border: 1px solid #FF7110;
}
.show-winners-icon:hover {
  transition: 0.3s ease all;
  background-color: #fff;
  color: #FF7110;
  border: 1px solid #FF7110;
}
.schedule-icon {
  color: #187C3B;
  cursor: pointer;
  margin-right: 10px;
}
.groups-icon {
  color: #FF7110;
  cursor: pointer;
  margin-right: 10px;
  font-size: 16px;
}
.hand-icon {
  color: #30aa4c;
  cursor: pointer;
  margin-right: 10px;
  font-size: 16px;
}
.campaign-icon {
  color: #FF7110;
  cursor: pointer;
  margin-right: 10px;
}
.folder-icon {
  color: #FF7110;
  cursor: pointer;
  margin-right: 10px;
}
.chart-icon {
  color: #FF7110;
  cursor: pointer;
  margin-right: 10px;
}
.refresh-icon {
  color: #17a2b8;
  cursor: pointer;
  margin-right: 10px;
}
.page-selected-filters-container {
  display: flex;
}
.page-selected-filters {
  background: #FCF9F7;
  border: 1px solid #E5E5E5;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 7px 12px;
  display: flex;
  align-items: center;
  margin-right: 10px;
}
.page-selected-filters-text {
  font-weight: 300;
  font-size: 14px;
  letter-spacing: 0.15px;
  color: #FF7110;
  margin-right: 5px;
}
.page-selected-filters-icon {
  color: #FF7110;
  cursor: pointer;
}

/deep/
.status-select .multiselect__tags {
  padding: 16px 25px 28px 10px !important;
}
/deep/
.input-containear {
  padding: 10px !important;
}
.mobile{
  display: none;
}

@media only screen and (max-width: 1000px){
  .page-table-header{
    display: none;
  }
  .page-container {
    font-size: 1.5vw;
    margin-right: 0;
  }
  .page-table-row{
    flex-direction: column;
    font-size: 1.2em;
  }
  .page-table-desc-column{
    display: flex;
    justify-content: space-between;
  }
  .page-table-header-mobile{
    display: block;
    font-weight: 600;
    font-size: 1.1em;
    color: #505050;
  }
  .obs-width {
    width: 100%;
  }
  .date-exp-width {
    width: 100%;
  }
  .num-quotation-width {
    width: 100%;
  }
  .products-width {
    width: 100%;
    text-align: center;
  }
  .mid-column-width {
    width: 100%;
  }
  .competitors-width {
    width: 100%;
    text-align: center;
  }
  .client-width {
    width: 100%;
  }
  .state-width {
    width: 100%;
    text-align: center;
  }
  .winners-width {
    width: 100%;
    text-align: center;
  }
  .actions-width {
    width: 100%;
  }
  .products-list-btn{width: 20%;}
  .competitors-list-btn{width: 20%;}
  
}
@media only screen and (max-width: 800px){
  .mobile{
    display: block;
  }
  .page-container {
    font-size: 2.5vw;
  }
  .requests-tab__actions-container{
    flex-wrap: wrap;
    margin: 0;
  }
  .requests-tab__actions-button-width{width: 45%;}
  .requests-tab__datetime-container{width: 45%;}
  .mobile-table-row{
    display: flex;
    justify-content: space-between;
    color: #505050;
  }
  .products-list-btn{
    width: 45vw;
  }
  .competitors-list-btn{
    width: 45vw;
  }
  .mobile-colum-end{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .mobile-colum-center{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .desktop{display: none;}
}
@media only screen and (max-width: 550px){
  .page-container {
    font-size: 3.5vw;
  }
}
@media only screen and (max-width: 400px){
  .page-container {
    font-size: 4vw;
  }
}
</style>
